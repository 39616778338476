<template>
<div id="page-modificar-tipo-cobro">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Modificar Tipos de Cobro en Ventas" class="elevation-1 px-5 py-3">
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container grid-list-md justify-center>
                <v-row>
                  <v-col sm="3" md="3" lg="3">
                    <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field v-on="on" v-model="model.fecha" label="Fecha" color="secondary" prepend-icon="event" @blur="model.fecha = parse_date(model.fecha)" :rules="[rules.required, valida_fecha]">
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="model.fecha" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                        <v-spacer />

                        <v-btn color="secondary" x-small @click="menu1 = false">
                          Cancelar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col sm="6" md="6" lg="6">
                    <v-autocomplete style="font-size: 9px !important" v-model="model.formas_pago" :items="formas_pago" :hide-no-data="true" multiple small-chips deletable-chips label="Seleccione Tipos de Cobro" item-text="nombre" item-value="nombre">
                    </v-autocomplete>
                  </v-col>
                  <v-col sm="3" md="3" lg="3">
                    <v-btn small dark color="blue" @click.native="searchVentas()" title="Buscar" v-tippy>
                      <v-icon>search</v-icon> Buscar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12" md="12" lg="12">
                    <span style="color:blue">
                      <h5>Crédito: {{conteo_credito}} &nbsp;&nbsp;&nbsp;
                        Débito: {{conteo_debito}} &nbsp;&nbsp;&nbsp;
                          Vales: {{conteo_vales}}</h5>
                    </span>
                    <v-client-table ref="vuetable" :data="registros" :columns="columns" :options="options">
                      <template slot="afterTable">                        
                        <div style="text-align:right">
                          <h5>
                            <span>Importe Crédito: <strong>${{formatNumberDec(total_credito,2)}}</strong></span><br>
                            <span>Importe Débito: <strong>${{formatNumberDec(total_debito,2)}}</strong></span><br>
                            Importe Vales: <strong>${{formatNumberDec(total_vales,2)}}</strong><br>
                            <span v-show="total_efectivo>0">Importe Efectivo: <strong>${{formatNumberDec(total_efectivo,2)}}</strong><br></span>
                            <span v-show="total_transferencia>0">Importe Transferencias: <strong>${{formatNumberDec(total_transferencia,2)}}</strong><br></span>
                            <span v-show="total_cheque>0">Importe Cheques: <strong>${{formatNumberDec(total_cheque,2)}}</strong><br></span>
                            TOTAL: <strong>${{formatNumberDec(sumatoria_total,2)}}</strong><br>
                          </h5>
                        </div>
                      </template>
                      <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                      </template>
                      <template slot="cantidad_metodo" slot-scope="props">
                        <div style="text-align:right !important;">${{formatNumberDec(props.row.cantidad_metodo,2)}}</div>
                    </template>
                      
                      <template slot="actions" slot-scope="props">
                        <v-btn x-small fab dark color="primary" @click.native="editar(props.row)" title="Editar" v-tippy>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                    </v-client-table>
                  </v-col> </v-row><br />
              </v-container>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal_editar" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Modificar Tipo de Cobro</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_editar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formcantidad" lazy-validation>
          <v-container>
            <v-row justify="center">
              <v-col sm="12" md="12" lg="12" style="text-align:center !important;">
                <v-autocomplete v-model="row_selected.metodo" :items="formas_pago" :hide-no-data="true" label="Seleccione" item-text="nombre" item-value="nombre">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col sm="12" md="12" lg="12" style="text-align:center !important;">
                <v-text-field label="Observaciones" v-model="row_selected.observaciones"></v-text-field>
              </v-col>
            </v-row>            
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row justify="center">
              <v-btn @click.native="modal_editar = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn color="success" @click.native="saveEditar()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

</div>
</template>

<script>
export default {
  components: {},
  mounted: function() {
    if (!this.verificaPermiso('t.ventas.tipos.cobro')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  computed: {
    total_credito() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Crédito") != -1)
              suma += parseFloat(result[k]["cantidad_metodo"]);
        }
        return suma;
    },
    total_debito() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Débito") != -1)
              suma += parseFloat(result[k]["cantidad_metodo"]);
        }
        return suma;
    },
    total_vales() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Vales") != -1)
              suma += parseFloat(result[k]["cantidad_metodo"]);
        }
        return suma;
    },
    total_efectivo() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Efectivo") != -1)
              suma += parseFloat(result[k]["cantidad_metodo"]);
        }
        return suma;
    },
    total_transferencia() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Transferencia") != -1)
              suma += parseFloat(result[k]["cantidad_metodo"]);
        }
        return suma;
    },
    total_cheque() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Cheque") != -1)
              suma += parseFloat(result[k]["cantidad_metodo"]);
        }
        return suma;
    },
    sumatoria_total() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){            
            suma += parseFloat(result[k]["cantidad_metodo"]);
        }
        return suma;
    },
    conteo_credito() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Crédito") != -1)
              suma += 1;
        }
        return suma;
    },
    conteo_debito() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Débito") != -1)
              suma += 1;
        }
        return suma;
    },
    conteo_vales() {
        let result = [];
        var suma = 0;
        if (this.$refs.vuetable != null) {            
            result = this.$refs.vuetable.allFilteredData;            
        } else {
            result = this.registros;            
        }
        for(var k in result){
            if(result[k]["metodo"].indexOf("Vales") != -1)
              suma += 1;
        }
        return suma;
    },
  },

  created: function() {
    this.get_formas_pago();
    var today = window.moment().format("YYYY-MM-DD");
    this.model.fecha = today;
  },
  data() {
    return {
      formas_pago: [],
      menu1: false,
      tipos_plantilla: [],
      model_filters: "",
      fab: [],
      name: "datagrid",
      columns: [        
        "caja",
        "folio",
        "fecha",
        "usuario",
        "sucursal.nombre",
        "metodo",
        "observaciones",
        "cantidad_metodo",
        "estatus", "actions"
      ],
      show_loading: true,
      options: {
        orderBy: {
          column: "ordena",
          ascending: true
        },
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["caja",
          "folio",
          "fecha","usuario",
          "sucursal.nombre",
          "metodo",
          "observaciones",
          "cantidad_metodo",
          "estatus"
        ],
        sortable: ["caja",
          "folio",
          "fecha","usuario",
          "sucursal.nombre",
          "metodo",
          "observaciones",
          "cantidad_metodo",
          "estatus"
        ],
        headings: {
          "caja": "Serie",
          "folio": "Folio",
          "fecha": "Fecha",
          "usuario":"Cajero",
          "sucursal.nombre":"Sucursal",
          "metodo": "Cobro",
          "cantidad_metodo": "Total",
          "actions": "Editar"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Ventas",
          disabled: true,
          href: ""
        },
        {
          text: "Modificar Tipos de Cobro",
          href: ""
        }
      ],

      modal: false,

      update: false,
      model_update: "",
      model: {
        formas_pago: [],
        fecha: "",
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: [],
      row_selected: "",
      modal_editar: false,                  
    };
  },
  methods: {
    editar: function(row) {
      this.row_selected = row;
      this.modal_editar = true;
    },
    sace: function(doc, req) {
      if (doc) {
        var datos = JSON.parse(req['body']);
        if (doc['pago'] != undefined) {
          for (var k in doc['pago']) {
            if (k == datos['indice']) {
              doc['pago'][k].metodo = datos['metodo'];
              doc['pago'][k].clave = datos['clave'];
            }
          }
        }
        return [doc, 'true'];
      } else {
        return [null, 'No se encontró la venta']
      }
    },
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            return "0";
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    saveEditar: function() {

      var self = this;
      window.dialogLoader.show('Espere un momento por favor..');

      try {
        var clave_nueva = this.getClaves([this.row_selected.metodo]);
        this.row_selected.clave_metodo = clave_nueva[0];
      } catch (error) {
        console.log("Error al buscar la clave del metodo: ", error);
      }

      let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_ventas/_update/modificar_pago/" + this.row_selected._id;
      let data = {
        indice: this.row_selected.indice_metodo,
        metodo: this.row_selected.metodo,
        clave: this.row_selected.clave_metodo,
        observaciones: this.row_selected.observaciones
      };

      window.axios
        .post(url, data)
        .then(response => {
          if (response.data == true) {
            window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
              color: 'success'
            });

            var filtro = this.registros.find(function(e) {
              return e._id == self.row_selected._id && e.indice_metodo == self.row_selected.indice_metodo;
            });

            if (filtro) {
              filtro.metodo = this.row_selected.metodo;
              filtro.clave_metodo = this.row_selected.clave_metodo;
              filtro.observaciones = this.row_selected.observaciones;
            }

          } else {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: response.data,
              footer: ""
            });
          }
        }).catch(error => {
          console.log(error);
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al modificar tipo. Intente nuevamente",
            footer: ""
          });
        }).then(() => {
          window.dialogLoader.hide();
          this.modal_editar = false;
        });

    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    searchVentas: function() {

      if (this.model.fecha != undefined && this.model.fecha != null && this.model.fecha != "") {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;

        let data = {
          "selector": {
            "type": "ventas",
            "estatus":{"$exists":true},
            "fecha": {
              "$gte": this.model.fecha,
              "$lte": this.model.fecha + "T23:59:59.999"
            },
          },
          "fields": ["_id", "usuario", "sucursal.nombre","caja", "folio", "fecha", "pago", "observaciones", "total", "estatus"],
          //"sort": ["type","caja","folio","fecha"],
          "use_index":"_design/d54236153ba037bbce44e2a6f12dbe4ebd9e9a84"
        };

        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/';

        axios.post(url, data)
          .then(response => {

            let respuesta = response.data.docs;

            self.registros = [];
            var folios = {};

            var formas_pago_clave = this.getClaves(self.model.formas_pago);
            
            respuesta.forEach(x => {
              for (var k in x.pago) {
                if (self.model.formas_pago.includes(x.pago[k].metodo) || formas_pago_clave.includes(x.pago[k].clave)) {
                  var record = JSON.parse(JSON.stringify(x));
                  //console.log(x);
                  record["ordena"] = x.caja + "-" + x.folio + "-" + x.fecha + "-" + x.pago[k].metodo;
                  record["metodo"] = x.pago[k].metodo;
                  record["clave_metodo"] = x.pago[k].clave;
                  record["indice_metodo"] = k;
                  record["cantidad_metodo"] = x.pago[k].cantidad;
                  record["observaciones"] = x.pago[k].observaciones;
                  record["fecha"] = x.fecha.substring(0, 16).replace("T", " ");
                  self.registros.push(record);
                }
              }
              
            });


          }).catch(error => {
            console.log(error);
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al consultar ventas. Intente nuevamente.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      }

    },

    getClaves: function(arr_formas) {
      var filtro = this.formas_pago.filter(function(e) {
        return arr_formas.includes(e.nombre);
      });
      var claves = [];
      if (filtro.length > 0) {
        for (var k in filtro) {
          claves.push(filtro[k].clave);
        }
      }
      return claves;
    },

    get_formas_pago: function() {

      let data = {
        'selector': {
          'type': 'sat_formas_pago'
        },        
      }

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            //   this.id_configuracion = response.data.docs[0]._id;
            this.formas_pago = response.data.docs;
            for (var k in this.formas_pago) {
              if (this.formas_pago[k].nombre.indexOf("Crédito") != -1 || this.formas_pago[k].nombre.indexOf("Débito") != -1 || this.formas_pago[k].nombre.indexOf("Despensa") != -1) {
                this.model.formas_pago.push(this.formas_pago[k].nombre);
              }
            }
          }

        })
        .catch(error => {
          console.log(error)
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las formas de pago.",
            footer: ""
          });
        });
    },
    ordenar_nombre: function(a, b) {
      if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
        return -1;
      }
      if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    ordenar_caja: function(a, b) {
      if (a.caja.toLowerCase() < b.caja.toLowerCase()) {
        return -1;
      }
      if (a.caja.toLowerCase() > b.caja.toLowerCase()) {
        return 1;
      }
      return 0;
    },


  }
};
</script>
